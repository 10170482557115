import { createContext } from 'react'

const BlogContext = createContext({
  blogName: 'Promova Blog',
  defaultSeo: {
    metaTitle: 'Promova Blog Article',
  },
  host: 'promova.com',
})
export default BlogContext
