import Head from 'next/head'
import { FC, useContext } from 'react'

import usePageUrl from '@promova/utils/usePageUrl'

import { SeoTypes } from '@_types/seo'

import { getStrapiMedia } from '@strapi/strapi'

import BlogContext from '@templates/Articles/Article/blogContext'

type SeoProps = {
  seo?: SeoTypes
  isBlogArticle?: boolean
  hostname?: string
}

const Seo: FC<SeoProps> = ({ seo, isBlogArticle = false, hostname }) => {
  const { defaultSeo, blogName, host } = useContext(BlogContext)
  let seoWithDefaults: SeoTypes

  if (isBlogArticle)
    seoWithDefaults = {
      ...defaultSeo,
      ...seo,
    }
  else {
    seoWithDefaults = {
      ...seo,
    }
  }
  const findSocialItem = (type: string) =>
    seoWithDefaults?.metaSocial?.find((item) => item.socialNetwork === type)
  const facebookMeta = findSocialItem('Facebook')
  const twitterMeta = findSocialItem('Twitter')
  const facebookMetaImage =
    facebookMeta?.image?.data && getStrapiMedia(facebookMeta?.image)
  const twitterMetaImage =
    twitterMeta?.image?.data && getStrapiMedia(twitterMeta?.image)

  const fullSeo = {
    ...seoWithDefaults,
    metaTitle: `${seoWithDefaults.metaTitle} ${
      isBlogArticle ? `| ${blogName}` : ''
    }`,
    metaImage:
      (seoWithDefaults?.metaImage?.data &&
        getStrapiMedia(seoWithDefaults?.metaImage)) ||
      undefined,
  }

  const url = usePageUrl(hostname || host)

  return (
    <Head>
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta
            property="og:title"
            content={facebookMeta?.title || fullSeo.metaTitle}
          />
          <meta
            name="twitter:title"
            content={twitterMeta?.title || fullSeo.metaTitle}
          />
        </>
      )}

      {fullSeo.metaDescription && (
        <>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta
            property="og:description"
            content={facebookMeta?.description || fullSeo.metaDescription}
          />
          <meta
            name="twitter:description"
            content={twitterMeta?.description || fullSeo.metaDescription}
          />
        </>
      )}

      {fullSeo.metaImage && (
        <>
          <meta name="image" content={fullSeo.metaImage} />
          <meta
            property="og:image"
            content={facebookMetaImage || fullSeo.metaImage}
          />
          <meta
            name="twitter:image"
            content={twitterMetaImage || fullSeo.metaImage}
          />
        </>
      )}

      {fullSeo.feedLinks &&
        fullSeo.feedLinks.map((feed) => (
          <link
            key={feed.title}
            rel="alternate"
            type="application/rss+xml"
            title={feed.title}
            href={feed.href}
          />
        ))}

      <link rel="canonical" href={fullSeo.canonicalURL || url} />

      {fullSeo.metaRobots && (
        <meta name="robots" content={fullSeo.metaRobots} />
      )}

      {fullSeo.keywords && <meta name="keywords" content={fullSeo.keywords} />}

      {fullSeo.author?.data && (
        <meta name="author" content={fullSeo.author.data.attributes.username} />
      )}

      {fullSeo.metaViewport && (
        <meta name="viewport" content={fullSeo.metaViewport} />
      )}

      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  )
}

export default Seo
