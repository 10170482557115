import { DEFAULT_LOCALE } from '@constants'

const getLocalizedItemAttr = (
  res: Record<string, any>,
  locale: string = DEFAULT_LOCALE
) => {
  let attributes = res?.attributes
  const localizations = attributes?.localizations?.data

  if (Array.isArray(localizations)) {
    const localizedItem = localizations.find(
      (item) => item?.attributes?.locale === locale
    )

    if (localizedItem) {
      attributes = localizedItem?.attributes
    }
  }

  return attributes
}

export default getLocalizedItemAttr
