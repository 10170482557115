import { useRouter } from 'next/router'

export default function usePageUrl(host?: string) {
  const hostUrl = host?.startsWith('http') ? host : `https://${host}`
  const siteUrl = hostUrl || process.env.SITE_URL
  const router = useRouter()
  const path = router?.asPath?.split('?')[0]

  return `${siteUrl}${
    router?.locale !== router?.defaultLocale ? `/${router?.locale}` : ''
  }${path !== '/' ? path : ''}`
}
