import { format } from 'date-fns'

import {
  clearNonBreakingSpaces,
  clearStringFromTags,
} from '@promova/utils/helpers'

import {
  BLOG_ROUTE,
  DEFAULT_LOCALE,
  LandingsRoutes,
  PagesRoutes,
  SITE_URL,
  TermsRoutes,
  directLinks,
  howToPronounceSitemap,
} from '@constants'

import { ArticleTypes, FaqBlock, FaqItem } from '@_types/articles'
import {
  ConfusingWordsDataTypes,
  ConfusingWordsPageTypes,
  FAQBlock,
  OtherArticlesCategoryTypes,
  OtherArticlesDataTypes,
  RelatedPagesInfoForSeo,
  Review,
} from '@_types/landings'
import { LocalesType } from '@_types/locales'
import { SeoTypes } from '@_types/seo'

import getLocalizedItemAttr from '@strapi/getLocalizedItemAttributes'

const SCHEMA_URL = 'https://schema.org'

const getLocalePrefix = (locale?: string) =>
  !locale || locale === DEFAULT_LOCALE ? '' : `/${locale}`

const formatUrl = (url?: string | null, locale?: string) =>
  url?.startsWith('http') ? url : `${SITE_URL}${getLocalePrefix(locale)}${url}`

type SchemaType = Record<string, any>

type MainEntityType = {
  '@type': string[]
  id: string
  name: string
  description: string
  audience: Record<string, string>
  educationalLevel: string[]
  provider: {
    '@type': string
    name: string
    url: string
    sameAs: string[]
  }
  about: {
    '@type': string
    id: string
    mainEntity: {
      '@type': string
      id: string
      questions: {
        '@type': string
        acceptedAnswer: {
          '@type': string
          text: string[]
          name: string
        }
      }
    }
  }
  aggregateRating?: Record<string, string | number>
}

type Link = {
  text: string
  url: string | null
}

type LinksList = Link[]

type QuizLink = {
  key: string
  value: string
}

const initialFirstLink = {
  text: 'Promova',
  url: '/',
}

const quizInitialFirstLink = {
  key: 'Promova',
  value: '/',
}

const createBreadcrumbsSchema = ({
  list = [],
  firstLink,
  locale,
}: {
  firstLink?: Link
  list?: LinksList
  locale?: string
}) => {
  const linksList = [firstLink || initialFirstLink, ...list]

  const itemListElement = linksList.map((link, index) => ({
    item: formatUrl(link?.url, locale),
    name: link.text,
    '@type': 'ListItem',
    position: index + 1,
  }))

  return {
    '@type': 'BreadcrumbList',
    '@context': SCHEMA_URL,
    itemListElement,
  }
}

const createBreadcrumbsQuizSchema = ({
  list = [],
  firstLink,
  locale,
}: {
  firstLink?: QuizLink
  list?: QuizLink[]
  locale?: string
}) => {
  const linksList = [firstLink || quizInitialFirstLink, ...list]

  const itemListElement = linksList.map((item, index) => ({
    item: formatUrl(item?.value, locale),
    name: item.key,
    '@type': 'ListItem',
    position: index + 1,
  }))

  return {
    '@type': 'BreadcrumbList',
    '@context': SCHEMA_URL,
    itemListElement,
  }
}

const publisherSchema = {
  url: SITE_URL,
  logo: {
    '@type': 'ImageObject',
    url: `${SITE_URL}/images/Promova_logo.svg`,
    width: 140,
    height: 31,
  },
  name: 'Promova',
  '@type': 'Organization',
  sameAs: Object.values(directLinks),
}

const promovaSchema = {
  ...publisherSchema,
  '@context': SCHEMA_URL,
  alternateName: 'promova.com',
}

type FAQPageSchemaProps = {
  faq?: FaqBlock | FAQBlock
  url?: string
  locale?: string
  idPostfix?: string
}

const createFAQSchema = ({
  faq,
  url,
  locale,
  idPostfix = '#faq',
}: FAQPageSchemaProps) => {
  const mainEntity = faq?.faq?.map((item) => {
    const cleanAnswer = clearNonBreakingSpaces(clearStringFromTags(item.answer))

    return {
      name: item.question,
      '@type': 'Question',
      acceptedAnswer: {
        text: cleanAnswer,
        '@type': 'Answer',
      },
    }
  })

  return {
    '@type': 'FAQPage',
    '@context': SCHEMA_URL,
    '@id': `${formatUrl(url, locale)}${idPostfix}`,
    mainEntity,
  }
}

type ReviewSchemaProps = {
  reviews: Review[]
}

const createReviewSchema = ({ reviews }: ReviewSchemaProps) =>
  reviews.map((review) => ({
    '@type': 'Review',
    author: review.author.name,
    reviewBody: clearNonBreakingSpaces(
      clearStringFromTags(review?.textExt || '') ||
        clearStringFromTags(review?.text) ||
        ''
    ),

    reviewRating: {
      '@type': 'Rating',
      bestRating: '5',
      ratingValue: review?.stars?.toString() || '5',
      worstRating: '1',
    },
  }))

const createBlogPostingSchema = (
  article: ArticleTypes,
  locale?: string
): SchemaType => ({
  '@type': 'BlogPosting',
  image: `${SITE_URL}${getLocalePrefix(locale)}/_next/image?url=${
    article?.attributes?.image?.data?.attributes?.url
  }&w=3840&q=75`,
  author: {
    url: `${SITE_URL}${getLocalePrefix(locale)}/author/${
      article?.attributes?.articleAuthor?.data?.attributes?.slug
    }`,
    name: article?.attributes?.articleAuthor?.data?.attributes?.name,
    '@type': 'Person',
  },
  '@context': SCHEMA_URL,
  headline: article?.attributes?.title,
  publisher: {
    name: 'Promova',
    '@type': 'Organization',
  },
  description: article?.attributes?.seo?.metaDescription,
  dateModified: article?.attributes?.updatedAt
    ? format(new Date(article?.attributes?.updatedAt), 'yyyy-MM-dd')
    : '',
  datePublished: format(
    new Date(article?.attributes?.publishedAt),
    'yyyy-MM-dd'
  ),
  mainEntityOfPage: {
    '@id': `${SITE_URL}${getLocalePrefix(locale)}${BLOG_ROUTE}/${
      article?.attributes?.slug
    }`,
    '@type': 'WebPage',
  },
})

const createImageSchema = (
  article: ArticleTypes | OtherArticlesDataTypes,
  locale?: LocalesType
): SchemaType => {
  const isOtherArticle = (
    articleData: typeof article
  ): articleData is OtherArticlesDataTypes =>
    Object.prototype.hasOwnProperty.call(
      articleData?.attributes,
      'other_articles_category'
    )

  return {
    '@type': 'ImageObject',
    '@id': `${SITE_URL}${getLocalePrefix(locale)}/${
      isOtherArticle(article)
        ? article?.attributes?.other_articles_category?.data?.attributes?.slug
        : 'blog'
    }/${article?.attributes?.slug}#image`,
    width: '1500',
    height: '1000',
    caption: article?.attributes?.title,
    creator: {
      name: 'Promova',
      '@type': 'Organization',
    },
    license: `${SITE_URL}${getLocalePrefix(locale)}${
      TermsRoutes.termsAndConditions
    }`,
    '@context': SCHEMA_URL,
    contentUrl: article?.attributes?.schemaImage?.data?.attributes?.url,
    creditText: 'Promova',
    inLanguage: locale || 'en',
    description: article?.attributes?.seo?.metaDescription,
    copyrightNotice: 'Promova',
    acquireLicensePage: `${SITE_URL}${getLocalePrefix(locale)}${
      TermsRoutes.termsAndConditions
    }`,
  }
}

export const createBlogSchema = (
  article: ArticleTypes,
  faq?: FaqBlock,
  locale?: LocalesType
) => {
  const breadCrumbs = [
    {
      text: 'Blog',
      url: BLOG_ROUTE,
    },
    {
      text: article?.attributes?.breadcrumb || article?.attributes?.title,
      url: `${BLOG_ROUTE}/${article?.attributes?.slug}`,
    },
  ]

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    locale,
  })
  const blogPostingSchema = createBlogPostingSchema(article, locale)

  const schema: SchemaType = [breadcrumbsSchema, promovaSchema]

  const schemaImageData = article?.attributes?.schemaImage?.data

  if (faq) {
    const FAQSchema = createFAQSchema({
      faq,
      url: `${BLOG_ROUTE}/${article?.attributes?.slug}`,
      locale,
    })

    schema.push(FAQSchema)

    const partOfPropBaseUrl = `${SITE_URL}${getLocalePrefix(
      locale
    )}${BLOG_ROUTE}/${article?.attributes?.slug}`

    blogPostingSchema.isPartOf = [
      {
        '@id': `${partOfPropBaseUrl}#faq`,
      },
    ]

    if (schemaImageData) {
      blogPostingSchema.isPartOf.push({
        '@id': `${partOfPropBaseUrl}#image`,
      })
    }
  }

  schema.push(blogPostingSchema)

  if (schemaImageData) {
    const imageSchema = createImageSchema(article, locale)
    schema.push(imageSchema)
  }

  return schema
}

export const createQuizSchema = ({
  title,
  description,
  breadCrumbs,
  url,
  questions,
}: any) => {
  const breadcrumbsSchema = createBreadcrumbsQuizSchema({
    list: breadCrumbs,
  })
  const webPageSchema: SchemaType = {
    '@type': 'WebPage',
    '@context': 'https://schema.org',
    name: title,
    description,
    url: `${SITE_URL}${url}`,
    significantLink: `${SITE_URL}/skills/english-grammar`,
  }

  const mainEntity: MainEntityType = {
    '@type': ['CreativeWork'],
    id: `${SITE_URL}${url}#questions`,
    name: title,
    description,
    audience: {
      '@type': 'EducationalAudience',
      educationalRole: 'Student',
    },
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: 'https://promova.com',
      sameAs: [
        'https://apps.apple.com/US/app/id1460782849',
        'https://play.google.com/store/apps/details?id=com.appsci.tenwords',
        'https://appgallery.huawei.com/app/C104818781',
        'https://www.instagram.com/promova/',
        'https://www.facebook.com/promova',
        'https://www.tiktok.com/@promova.app',
        'https://www.crunchbase.com/organization/promova',
        'https://www.wikidata.org/wiki/Q122227827',
      ],
    },
    about: {
      '@type': 'Quiz',
      id: `${SITE_URL}${url}#questions`,
      mainEntity: questions,
    },
  }

  const schema: SchemaType = {
    breadcrumb: { ...breadcrumbsSchema },
    ...webPageSchema,
    mainEntity,
  }

  return schema
}

export const createOtherArticleSchema = (
  article: OtherArticlesDataTypes,
  category: { attributes: OtherArticlesCategoryTypes } | undefined,
  locale?: LocalesType
) => {
  const localizedArticle = getLocalizedItemAttr(article, DEFAULT_LOCALE)
  let localizedCategory

  if (category) {
    localizedCategory = getLocalizedItemAttr(category, locale || DEFAULT_LOCALE)
  }

  const breadCrumbs = [
    {
      text: localizedCategory?.title,
      url: category?.attributes?.customUrl || null,
    },
    {
      text: article?.attributes?.breadcrumb || article?.attributes?.title,
      url: `/${category?.attributes?.slug}/${localizedArticle.slug}`,
    },
  ]

  const imageSchema = createImageSchema(article, locale)

  const mainSchema: SchemaType = {
    '@type': ['Article'],
    headline: article?.attributes?.title,
    dateModified: article?.attributes?.updatedAt
      ? format(new Date(article?.attributes?.updatedAt), 'yyyy-MM-dd')
      : '',
    datePublished: format(
      new Date(article?.attributes?.publishedAt),
      'yyyy-MM-dd'
    ),
    description: article?.attributes?.seo?.metaDescription,
    publisher: publisherSchema,
    '@context': SCHEMA_URL,
    mainEntityOfPage: {
      '@id': `${SITE_URL}${getLocalePrefix(locale)}/${
        category?.attributes?.slug
      }/${localizedArticle.slug}`,
      '@type': ['WebPage'],
      breadcrumb: createBreadcrumbsSchema({ list: breadCrumbs, locale }),
    },
  }

  const schema: SchemaType = {
    '@graph': [],
  }

  if (article?.attributes?.schemaImage?.data) {
    schema['@graph'].push(imageSchema)

    mainSchema.isPartOf = {
      '@id': `${SITE_URL}${getLocalePrefix(locale)}/${
        article?.attributes?.other_articles_category?.data?.attributes?.slug
      }/${article?.attributes?.slug}#image`,
    }
  }

  schema['@graph'].push(mainSchema)

  return schema
}

const howToPronounceDefaultWord = 'word'

export const createHowToPronounceSchema = (
  word: string,
  definition?: string
) => {
  const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1)
  const breadCrumbs = [
    {
      text: 'All words',
      url: howToPronounceSitemap,
    },
    {
      text: 'How to Pronounce',
      url: LandingsRoutes.howToPronounce,
    },
  ]

  if (word !== howToPronounceDefaultWord) {
    breadCrumbs.push({
      text: `Learn How to Pronounce '${word}'`,
      url: `${LandingsRoutes.howToPronounce}/${word}`,
    })
  }

  const mainEntity: Record<string, any> = {
    '@context': SCHEMA_URL,
    '@type': 'DefinedTermSet',
    name: `How to Pronounce ${capitalizedWord}`,
    description: `Find how to pronounce ${word} and practice it in our free word pronouncer for English learners. Try the Promova pronunciation tool!`,
    url: `${SITE_URL}/how-to-pronounce/${word}`,
    inLanguage: 'en',
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
  }

  if (definition) {
    mainEntity.hasDefinedTerm = [
      {
        '@type': 'DefinedTerm',
        name: 'Definition',
        description: definition,
      },
    ]
  }

  return {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: `How to Pronounce ${capitalizedWord}`,
    description: `Find how to pronounce ${word} and practice it in our free word pronouncer for English learners. Try the Promova pronunciation tool!`,
    url: `${SITE_URL}/how-to-pronounce/${word}`,
    significantLink: `${SITE_URL}/how-to-pronounce`,
    breadcrumb: createBreadcrumbsSchema({
      list: breadCrumbs,
      firstLink: {
        text: 'Learn languages',
        url: '/',
      },
    }),
    mainEntity,
  }
}

type ConfusingWordsSchemaProps = {
  title: SeoTypes['metaDescription']
  name: ConfusingWordsPageTypes['wordsTitle']
  url: string
  relatedLink: string[]
  breadcrumb: Record<string, any>
  wordData: ConfusingWordsDataTypes['wordData']
}

type DefinedTerm = {
  '@type': 'DefinedTerm'
  name: string
  description: string[]
}

export const createConfusingWordsSchema = ({
  title,
  name,
  url,
  relatedLink,
  breadcrumb,
  wordData,
}: ConfusingWordsSchemaProps) => {
  const [firstWord, secondWord] = Object.keys(wordData)
  const hasDefinedTermData = Object.entries(wordData).reduce(
    (acc, [wordName, wordObj]) => {
      const data: DefinedTerm = {
        '@type': 'DefinedTerm',
        name: wordName,
        description: [
          wordObj.Meaning.value.join(''),
          wordObj.Examples.value.join(''),
        ],
      }
      return [...acc, data]
    },
    [] as DefinedTerm[]
  )

  return {
    '@type': 'WebPage',
    '@context': 'https://schema.org',
    name,
    description: title,
    url,
    significantLink: 'https://promova.com/confusing-words',
    relatedLink,
    breadcrumb: {
      ...breadcrumb,
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Learn languages',
          item: 'https://promova.com/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'All words',
          item: 'https://promova.com/sitemap/confusing-words',
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: 'Part of Speech Identifier',
          item: 'https://promova.com/confusing-words',
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: `Confusing words is '${firstWord}', '${secondWord}'`,
          item: url,
        },
      ],
    },
    mainEntity: {
      '@context': 'https://schema.org/',
      '@type': ['DefinedTermSet', 'CreativeWork'],
      '@id': `${url}#faq`,
      name,
      description: title,
      url,
      inLanguage: 'en',
      educationalLevel: [
        'Beginner',
        'Elementary',
        'Pre-Intermediate',
        'Intermediate',
        'Upper-Intermediate',
        'Advanced',
        'Proficient',
      ],
      hasDefinedTerm: hasDefinedTermData,
      provider: {
        '@type': 'Organization',
        name: 'Promova',
        url: 'https://promova.com',
        sameAs: [
          'https://apps.apple.com/US/app/id1460782849',
          'https://play.google.com/store/apps/details?id=com.appsci.tenwords',
          'https://appgallery.huawei.com/app/C104818781',
          'https://www.instagram.com/promova/',
          'https://www.facebook.com/promova',
          'https://www.tiktok.com/@promova.app',
          'https://www.crunchbase.com/organization/promova',
          'https://www.wikidata.org/wiki/Q122227827',
        ],
      },
    },
    hasPart: [
      {
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: 'How can I learn English by reading?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'The answer depends on your current skills and how well you learn from books and online content. Reading helps you develop vocabulary, understand the structure, and pick up nuances of punctuation and grammar from seeing how others use it. You can learn simply by engaging in active reading while trying to improve your language skills.Some people can absorb English from textbooks and develop incredible skills from reading alone. However, they’re incredibly rare, and you’ll most likely need some help and advice on your journey to fluency.If you choose to take a Promova English class, reading will also be combined with other activities to help you develop overall comprehension. For example, you’ll interact with a certified tutor who will ask you questions about the text and your opinions, improving your communication skills and expression.',
            },
          },
          {
            '@type': 'Question',
            name: 'How can I practice English reading comprehension?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'There are several ways to improve your comprehension:Ask Questions. Start your reading with a quick skim of the upcoming text to prepare for what you’re about to read. After reading a section, quickly go through it again to see if there’s anything you missed.Skim and re-read. Start your reading with a quick skim of the upcoming text to prepare for what you’re about to read. After you’re done reading a section, quickly go through it again to see if there’s anything you missed.Look for context. Texts don’t exist in isolation and usually have many references, history, or related materials. If you can find out where the author is coming from, you can understand ideas much more quickly.Improve fluency. Spend time sharpening other skills such as listening, speaking, and writing. You can get back to reading later with a new vocabulary and awareness of the language.Lastly, taking English reading and writing classes can help you see the language from various angles and develop better comprehension by engaging with a teacher and getting feedback.',
            },
          },
          {
            '@type': 'Question',
            name: 'What types of English reading can I learn?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'We separate reading into three types based on your purpose - why you might be doing it:Skimming. You’re reading for gist - to get the main ideas from the text. For example, you might be deciding whether a book is worth buying by skimming different chapters to see if it interests you.Scanning. You’re reading to get something in particular from the text and retain specific information. For example, you might be reading a restaurant menu trying to find a dish with shrimps in it, then you’re scanning through the text for the word ‘shrimp’.Intensive reading. You’re reading for detailed comprehension. For example, you’ve received an email from a friend who has just relocated to Canada in which he or she is telling you about their first few weeks in this country. In this case, you might be interested in all the details and even some hidden nuances and inferences, so you will be reading for detail.Extensive reading. You’re reading easy, enjoyable texts without focusing on details or doing any comprehension tasks or examining the text for vocabulary or grammar. This type of reading helps build up fluency by getting used to the language.Learning all the above techniques can help you adjust your reading style and speed depending on your purpose.',
            },
          },
        ],
        about: {
          '@type': 'Thing',
          '@id': `${url}#faq`,
        },
      },
    ],
  }
}

export const createPartOfSpeechSchema = (word: string) => ({
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: `What part of speech is the word ${word}?`,
  description: `An educational resource explaining the different parts of speech for the word ${word} in English, including its use as an adverb, article, and more.`,
  url: `https://promova.com/what-part-of-speech/${word}`,
  relatedLink: [
    'https://promova.com/english-grammar/nouns-in-english',
    'https://promova.com/english-grammar/adjectives-in-english',
    'https://promova.com/english-grammar/pronouns-in-english',
    'https://promova.com/english-grammar/verbs-in-english',
    'https://promova.com/english-grammar/adverbs-in-english',
    'https://promova.com/english-grammar/prepositions-in-english',
    'https://promova.com/english-grammar/conjunctions-in-english',
    'https://promova.com/english-grammar/interjections-in-english',
  ],
  significantLink: 'https://promova.com/what-part-of-speech',
  breadcrumb: {
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Learn languages',
        item: 'https://promova.com/',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'All words',
        item: 'https://promova.com/sitemap/what-part-of-speech-is',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Part of Speech Identifier',
        item: 'https://promova.com/what-part-of-speech',
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: `What part of speech is ${word}`,
        item: `https://promova.com/what-part-of-speech/${word}`,
      },
    ],
  },
  mainEntity: {
    '@context': 'https://schema.org/',
    '@type': ['DefinedTermSet', 'CreativeWork'],
    name: `What part of speech is the word ${word}?`,
    description: `An educational resource explaining the different parts of speech for the word ${word} in English, including its use as an adverb, article, and more.`,
    url: `https://promova.com/what-part-of-speech/${word}`,
    inLanguage: 'en',
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    hasDefinedTerm: [
      {
        '@type': 'DefinedTerm',
        name: 'Adverb',
        description: `In certain contexts, ${word} functions as an adverb, usually indicating the measure of extension.`,
      },
      {
        '@type': 'DefinedTerm',
        name: 'Article',
        description: `${word} is a definite article in English, used to introduce a noun, specifying it as something known or unique.`,
      },
      // Additional parts of speech for 'the' can be added here.
    ],
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: 'https://promova.com',
      sameAs: [
        'https://apps.apple.com/US/app/id1460782849',
        'https://play.google.com/store/apps/details?id=com.appsci.tenwords',
        'https://appgallery.huawei.com/app/C104818781',
        'https://www.instagram.com/promova/',
        'https://www.facebook.com/promova',
        'https://www.tiktok.com/@promova.app',
        'https://www.crunchbase.com/organization/promova',
        'https://www.wikidata.org/wiki/Q122227827',
      ],
    },
  },
})

type LandingSchemaProps = {
  title: string
  description?: string
  url: string
  faq?: FAQBlock
  locale?: string
}

export const createLandingSchema = ({
  title,
  description,
  url,
  faq,
  locale,
}: LandingSchemaProps) => {
  const breadCrumbs = []
  const path = url.split('?')[0]

  if (path !== '/')
    breadCrumbs.push({
      text: title,
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    locale,
  })
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: clearStringFromTags(title),
    description,
    publisher: publisherSchema,
  }

  const schema: SchemaType = [breadcrumbsSchema]

  if (faq) {
    webPageSchema.isPartOf = {
      '@id': `${SITE_URL}${getLocalePrefix(locale)}${
        path === '/' ? '' : path
      }#faq`,
    }

    const faqSchema = createFAQSchema({
      faq,
      url: path,
    })

    schema.push(faqSchema)
  }

  schema.push(webPageSchema)

  return schema
}

type SkillsSchemaProps = {
  title: string
  description: string
  url: string
  faq?: FAQBlock
  locale?: string
  relPagesInfo?: RelatedPagesInfoForSeo[]
  onlineEnglishClassesTitle?: string
}

const LearnEnglishOnlineTitles: Record<string, string> = {
  en: 'Learn English Online',
  es: 'Aprende inglés en línea',
  uk: 'Вивчай англійську онлайн',
}
export const createSkillsSchema = ({
  title,
  description,
  url,
  faq,
  locale,
  relPagesInfo,
  onlineEnglishClassesTitle,
}: SkillsSchemaProps) => {
  const breadCrumbs = [
    {
      url: `${SITE_URL}${getLocalePrefix(locale)}${
        LandingsRoutes.learnEnglishOnline
      }`,
      text:
        onlineEnglishClassesTitle ||
        // fallback to default title
        LearnEnglishOnlineTitles[locale || ''] ||
        LearnEnglishOnlineTitles.en,
    },
  ]
  const path = url.split('?')[0]

  if (path !== '/')
    breadCrumbs.push({
      text: clearStringFromTags(title),
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    locale,
  })
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: clearStringFromTags(title),
    description,
    url: `${SITE_URL}${getLocalePrefix(locale)}${path}`,
  }

  const mainEntity: SchemaType = {
    '@type': ['CreativeWork'],
    id: `${SITE_URL}${getLocalePrefix(locale)}${path}#CreativeWork`,
    name: clearStringFromTags(title),
    description,
    audience: {
      '@type': 'EducationalAudience',
      educationalRole: 'Student',
    },
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
  }

  let faqSchema: SchemaType = {}
  if (faq) {
    faqSchema = createFAQSchema({
      faq,
      url: path,
      idPostfix: '#Course',
      locale,
    })
  }

  let hasPartSchema: SchemaType = []
  if (relPagesInfo?.length) {
    webPageSchema.relatedLink = relPagesInfo.map((item) => item.url)

    hasPartSchema = [
      {
        ...faqSchema,
        about: {
          '@type': 'Thing',
          id: `${SITE_URL}${getLocalePrefix(locale)}${path}#CreativeWork`,
        },
      },
    ]
  }

  webPageSchema.significantLink = `${SITE_URL}${getLocalePrefix(locale)}${
    LandingsRoutes.learnEnglishOnline
  }`

  const schema: SchemaType = {
    ...webPageSchema,
    breadcrumb: { ...breadcrumbsSchema },
    mainEntity,
    hasPart: hasPartSchema,
  }
  return schema
}

type ClassesSchemaProps = {
  title: string
  description: string
  url: string
  faq?: FAQBlock
  locale?: string
  relPagesInfo?: RelatedPagesInfoForSeo[]
  onlineEnglishClassesTitle: string
}

const OnlineEnglishClassesTitles: Record<string, string> = {
  en: 'Online English classes',
  es: 'Clases de inglés en línea',
  uk: 'Уроки англійської онлайн',
}
export const createClassesSchema = ({
  title,
  description,
  url,
  faq,
  locale,
  relPagesInfo,
  onlineEnglishClassesTitle,
}: ClassesSchemaProps) => {
  const breadCrumbs = [
    {
      url: `${SITE_URL}${getLocalePrefix(locale)}${
        LandingsRoutes.onlineEnglishClasses
      }`,
      text:
        onlineEnglishClassesTitle ||
        // fallback to default title
        OnlineEnglishClassesTitles[locale || ''] ||
        OnlineEnglishClassesTitles.en,
    },
  ]
  const path = url.split('?')[0]

  if (path !== '/')
    breadCrumbs.push({
      text: clearStringFromTags(title),
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    firstLink: {
      text: 'Learn Languages',
      url: '/',
    },
    locale,
  })
  const currentUrl = `${SITE_URL}${getLocalePrefix(locale)}${path}`
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: clearStringFromTags(title),
    description,
    url: currentUrl,
  }

  const mainEntity: SchemaType = {
    '@type': ['CreativeWork', 'LearningResource'],
    id: `${currentUrl}#LearningResource`,
    name: clearStringFromTags(title),
    description,
    audience: {
      '@type': 'EducationalAudience',
      educationalRole: 'Student',
    },
    offers: {
      '@type': 'Offer',
      url: currentUrl,
      availability: 'http://schema.org/InStock',
    },
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    learningResourceType: 'Online Class',
    timeRequired: 'P0DT1H0M0S',
    interactivityType: 'Mixed',
    isAccessibleForFree: false,
    typicalAgeRange: 'Adults',
    license: `${SITE_URL}${getLocalePrefix(locale)}${
      TermsRoutes.termsAndConditions
    }`,
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
  }

  let faqSchema: SchemaType = {}
  if (faq) {
    faqSchema = createFAQSchema({
      faq,
      url: path,
      idPostfix: '#Course',
      locale,
    })
  }

  let hasPartSchema: SchemaType = []
  if (relPagesInfo?.length) {
    webPageSchema.relatedLink = [
      `${SITE_URL}${getLocalePrefix(locale)}${
        LandingsRoutes.onlineEnglishClasses
      }`,
      `${SITE_URL}${getLocalePrefix(locale)}${LandingsRoutes.groupLessons}`,
      ...relPagesInfo.map((item) => item.url),
    ]

    hasPartSchema = [
      {
        ...faqSchema,
        about: {
          '@type': 'Thing',
          id: `${currentUrl}#LearningResource`,
        },
      },
    ]
  }

  webPageSchema.significantLink = `${SITE_URL}${getLocalePrefix(locale)}${
    PagesRoutes.tutoringPricing
  }`

  const schema: SchemaType = {
    ...webPageSchema,
    breadcrumb: { ...breadcrumbsSchema },
    mainEntity,
    hasPart: hasPartSchema,
  }
  return schema
}

const OnlineEnglishTutorsTitles: Record<string, string> = {
  en: 'Online English Tutors',
  es: 'Tutores de inglés en línea',
  uk: 'Репетитори англійської онлайн',
}
type TutorsSchemaProps = {
  title: string
  description: string
  url: string
  faq?: FAQBlock
  locale?: string
  relPagesInfo?: RelatedPagesInfoForSeo[]
  onlineEnglishTutorsTitle?: string
}

type OfferElement = {
  '@type': 'Offer'
  itemOffered: {
    '@type': 'Service'
    name: string
  }
}

function generateOfferElements(serviceNames: string[]): OfferElement[] {
  return serviceNames.map((serviceName) => ({
    '@type': 'Offer',
    itemOffered: {
      '@type': 'Service',
      name: serviceName,
    },
  }))
}

const createOfferCatalog = (name: string, serviceNames: string[]): any => ({
  '@type': 'OfferCatalog',
  name,
  itemListElement: [
    {
      '@type': 'OfferCatalog',
      itemListElement: generateOfferElements(serviceNames),
      name: 'Lesson Duration Options',
    },
  ],
})

export const createTutorsSchema = ({
  title,
  description,
  url,
  faq,
  locale,
  relPagesInfo,
  onlineEnglishTutorsTitle,
}: TutorsSchemaProps) => {
  const breadCrumbs = [
    {
      url: `${SITE_URL}${getLocalePrefix(locale)}${
        LandingsRoutes.onlineEnglishTutors
      }`,
      text:
        onlineEnglishTutorsTitle ||
        // fallback to default title
        OnlineEnglishTutorsTitles[locale || ''] ||
        OnlineEnglishTutorsTitles.en,
    },
  ]
  const path = url.split('?')[0]

  if (path !== '/')
    breadCrumbs.push({
      text: clearStringFromTags(title),
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    firstLink: {
      text: 'Learn Languages',
      url: '/',
    },
    locale,
  })
  const name = `${clearStringFromTags(title)} | Promova`
  const currentUrl = `${SITE_URL}${getLocalePrefix(locale)}${path}`
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name,
    description,
    url: currentUrl,
  }

  // we don't have these values in Strapi, so we use hardcoded values
  const acceptedCurrencies = ['EUR', 'PLN', 'GBP', 'USD', 'BRL', 'UAH']
  const acceptedPaymentMethods = ['Credit Card', 'Debit Card', 'PayPal']
  const RATING_VALUE = 4
  const RATING_COUNT = 1234
  const REVIEW_COUNT = 450

  const mainEntity: SchemaType = {
    '@type': ['LocalBusiness', 'CreativeWork'],
    id: `${currentUrl}#faq`,
    name,
    url: currentUrl,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Ukraine, Cyprus',
    },
    currenciesAccepted: acceptedCurrencies.join(','),
    paymentAccepted: acceptedPaymentMethods.join(', '),
    openingHours: 'Mo-Su',
    areaServed: 'Worldwide',
    priceRange: '$9.17/25 minutes to $16.49/25 minutes (avg: $13/25 minutes)',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: RATING_VALUE,
      ratingCount: RATING_COUNT,
      reviewCount: REVIEW_COUNT,
    },
    hasOfferCatalog: createOfferCatalog('English Tutoring Services', [
      '25-minute session',
      '50-minute session',
      '90-minute session',
    ]),
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
  }

  let faqSchema: SchemaType = {}
  if (faq) {
    faqSchema = createFAQSchema({
      faq,
      url: path,
      idPostfix: '#faq',
      locale,
    })
  }

  let hasPartSchema: SchemaType = []
  if (relPagesInfo?.length) {
    webPageSchema.relatedLink = [...relPagesInfo.map((item) => item.url)]

    hasPartSchema = [
      {
        ...faqSchema,
        about: {
          '@type': 'Thing',
          id: `${currentUrl}#faq`,
        },
      },
    ]
  }

  webPageSchema.significantLink = `${SITE_URL}${getLocalePrefix(locale)}${
    LandingsRoutes.learnEnglishOnline
  }`

  const schema: SchemaType = {
    ...webPageSchema,
    breadcrumb: { ...breadcrumbsSchema },
    mainEntity,
    hasPart: hasPartSchema,
  }
  return schema
}

type LevelsSchemaProps = {
  title: string
  description: string
  url: string
  faq?: FAQBlock
  locale?: string
  relLevelsInfo?: RelatedPagesInfoForSeo[]
  reviews: Review[]
}

enum SECOND_LEVELS_BREADCRUMB_TITLE {
  en = 'English Proficiency Levels',
  es = 'Niveles de dominio del inglés',
}

// hard-code for now as we don't have this data in the strapi
const HARDCODED_REVIEWS_ELEMENTS = {
  ratingValue: 4,
  ratingCount: 1234,
  reviewCount: 450,
}
// we hardcode that values, because fetching additional
// entity for only one field is not efficient, when the page
// is already overloaded with data (500+kb, when the normal size is 128kb)
const secondBreadcrumbTitle: Record<string, string> = {
  en: SECOND_LEVELS_BREADCRUMB_TITLE.en,
  es: SECOND_LEVELS_BREADCRUMB_TITLE.es,
}
export const createLevelsSchema = ({
  title,
  description,
  url,
  faq,
  locale,
  relLevelsInfo,
  reviews,
}: LevelsSchemaProps) => {
  const breadCrumbs = []
  const path = url.split('?')[0]

  if (path !== '/' && Object.keys(secondBreadcrumbTitle).includes(locale || ''))
    breadCrumbs.push({
      text: secondBreadcrumbTitle[locale || ''],
      url: `${SITE_URL}${getLocalePrefix(locale)}/blog/english-levels`,
    })

  if (path !== '/')
    breadCrumbs.push({
      text: clearStringFromTags(title),
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    locale,
  })
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: clearStringFromTags(title),
    description,
    url: `${SITE_URL}${getLocalePrefix(locale)}${path}`,
  }
  let faqSchema = {}
  if (faq) {
    faqSchema = createFAQSchema({
      faq,
      url: path,
      idPostfix: '#Course',
      locale,
    })
  }

  let reviewsSchema: any[] = []

  if (reviews) {
    reviewsSchema = createReviewSchema({ reviews })
  }

  const mainEntity: SchemaType = {
    '@type': ['CreativeWork', 'Course'],
    id: `${SITE_URL}${getLocalePrefix(locale)}${path}#Course`,
    name: clearStringFromTags(title),
    description,
    offers: [
      {
        '@type': 'Offer',
        category: 'Paid',
        price: 9.99,
        priceCurrency: 'USD',
      },
    ],
    hasCourseInstance: {
      '@type': 'CourseInstance',
      courseMode: 'online',
      courseSchedule: {
        '@type': 'Schedule',
        repeatCount: 7,
        repeatFrequency: ['Weekly', 'Daily'],
      },
    },
    audience: {
      '@type': 'EducationalAudience',
      educationalRole: 'Student',
    },
    educationalLevel: [clearStringFromTags(title)],
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: HARDCODED_REVIEWS_ELEMENTS.ratingValue,
      ratingCount: HARDCODED_REVIEWS_ELEMENTS.ratingCount,
      reviewCount: HARDCODED_REVIEWS_ELEMENTS.reviewCount,
    },
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
    about: faqSchema,
    review: reviewsSchema,
  }

  if (relLevelsInfo?.length) {
    webPageSchema.relatedLink = relLevelsInfo.map((item) => item.url)

    mainEntity.hasPart = relLevelsInfo.map((item) => ({
      '@type': 'Course',
      name: item.title,
      url: item.url,
      description: item.description,
    }))
  }

  webPageSchema.significantLink = `${SITE_URL}${getLocalePrefix(locale)}${
    LandingsRoutes.learnEnglishOnline
  }`

  const schema: SchemaType = {
    ...webPageSchema,
    breadcrumb: { ...breadcrumbsSchema },
    mainEntity,
  }

  return schema
}

type PractiseSchemaProps = {
  title: string
  description: string
  url: string
  faq?: FAQBlock
  locale?: string
  relPagesInfo?: RelatedPagesInfoForSeo[]
}
export const createPracticeSchema = ({
  title,
  description,
  url,
  faq,
  locale,
  relPagesInfo,
}: PractiseSchemaProps) => {
  const breadCrumbs = []
  const path = url.split('?')[0]

  if (path !== '/')
    breadCrumbs.push({
      text: clearStringFromTags(title),
      url: path,
    })

  const breadcrumbsSchema = createBreadcrumbsSchema({
    list: breadCrumbs,
    locale,
  })
  const webPageSchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': 'WebPage',
    name: clearStringFromTags(title),
    description,
    url: `${SITE_URL}${getLocalePrefix(locale)}${path}`,
  }

  let faqSchema = {}
  if (faq) {
    faqSchema = createFAQSchema({
      faq,
      url: path,
      idPostfix: '#Course',
      locale,
    })
  }

  const mainEntity: SchemaType = {
    '@type': ['CreativeWork', 'Course'],
    id: `${SITE_URL}${getLocalePrefix(locale)}${path}#Course`,
    name: clearStringFromTags(title),
    description,
    offers: [
      {
        '@type': 'Offer',
        category: 'Paid',
        price: 9.99,
        priceCurrency: 'USD',
      },
    ],
    hasCourseInstance: {
      '@type': 'CourseInstance',
      courseMode: 'online',
      courseSchedule: {
        '@type': 'Schedule',
        repeatCount: 7,
        repeatFrequency: ['Weekly', 'Daily'],
      },
    },
    audience: {
      '@type': 'EducationalAudience',
      educationalRole: 'Student',
    },
    educationalLevel: [clearStringFromTags(title)],
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: HARDCODED_REVIEWS_ELEMENTS.ratingValue,
      ratingCount: HARDCODED_REVIEWS_ELEMENTS.ratingCount,
      reviewCount: HARDCODED_REVIEWS_ELEMENTS.reviewCount,
    },
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
    about: faqSchema,
  }

  if (relPagesInfo?.length) {
    webPageSchema.relatedLink = relPagesInfo.map((item) => item.url)

    mainEntity.hasPart = relPagesInfo.map((item) => ({
      '@type': 'Course',
      name: item.title,
      url: item.url,
      description: item.description,
    }))
  }

  webPageSchema.significantLink = `${SITE_URL}${getLocalePrefix(locale)}${
    LandingsRoutes.learnEnglishOnline
  }`

  const schema: SchemaType = {
    ...webPageSchema,
    breadcrumb: { ...breadcrumbsSchema },
    mainEntity,
  }
  return schema
}

type PastTenseOfSlugSchemaProps = {
  word: string
  description: string
  verbFormInfo: {
    title: string
    examples: string[]
  }[]
  faqs: FaqItem[]
}

type CreatePastTenseOfSchemaMainEntityProps = Pick<
  PastTenseOfSlugSchemaProps,
  'word' | 'verbFormInfo' | 'description'
>
const createPastTenseOfSchemaMainEntity = ({
  word,
  verbFormInfo,
  description,
}: CreatePastTenseOfSchemaMainEntityProps) => {
  const wordCapitalized = word.charAt(0).toUpperCase() + word.slice(1)

  const url = `${SITE_URL}/past-tense-of/${word}`

  const definedTermData = verbFormInfo?.map(({ title, examples }) => ({
    '@type': 'DefinedTerm',
    name: title,
    description: examples,
  }))

  const mainEntitySchema: SchemaType = {
    '@context': SCHEMA_URL,
    '@type': ['DefinedTermSet', 'CreativeWork'],
    '@id': `${url}#faq`,
    name: `${wordCapitalized} past tense`,
    description,
    url,
    inLanguage: 'en',
    educationalLevel: [
      'Beginner',
      'Elementary',
      'Pre-Intermediate',
      'Intermediate',
      'Upper-Intermediate',
      'Advanced',
      'Proficient',
    ],
    hasDefinedTerm: definedTermData,
    provider: {
      '@type': 'Organization',
      name: 'Promova',
      url: SITE_URL,
      sameAs: Object.values(directLinks),
    },
  }
  return mainEntitySchema
}

const createFaqSchemaForPastTenseOf = (faqs: FaqItem[], word: string) => {
  const qaList = faqs?.map(({ question, answer }) => ({
    '@type': 'Question',
    name: clearNonBreakingSpaces(clearStringFromTags(question)),
    acceptedAnswer: {
      '@type': 'Answer',
      text: clearNonBreakingSpaces(clearStringFromTags(answer)),
    },
  }))

  return {
    '@type': 'FAQPage',
    mainEntity: qaList,
    about: {
      '@type': 'Thing',
      '@id': `${SITE_URL}/past-tense-of/${word}#faq`,
    },
  }
}

export const createPastTenseOfSlugSchema = ({
  word,
  verbFormInfo,
  faqs,
  description,
}: PastTenseOfSlugSchemaProps) => {
  try {
    const wordCapitalized = word.charAt(0).toUpperCase() + word.slice(1)

    const baseInfoSchema: SchemaType = {
      '@type': 'WebPage',
      '@context': SCHEMA_URL,
      name: `${wordCapitalized} past tense`,
      description,
      url: `${SITE_URL}/past-tense-of/${word}`,
      significantLink: `${SITE_URL}/past-tense-of`,
    }

    const firstBreadcrumbLink: Link = {
      text: 'Learn languages',
      url: SITE_URL,
    }

    const breadCrumbLinks: LinksList = [
      {
        text: 'All words',
        url: `${SITE_URL}/sitemap/past-tense-of`,
      },
      {
        text: 'Past Tense Of',
        url: `${SITE_URL}/past-tense-of`,
      },
      {
        text: `Past Tense Of ${word}`,
        url: `${SITE_URL}/past-tense-of/${word}`,
      },
    ]

    const breadcrumbSchema: SchemaType = createBreadcrumbsSchema({
      firstLink: firstBreadcrumbLink,
      list: breadCrumbLinks,
    })

    const mainEntitySchema = createPastTenseOfSchemaMainEntity({
      word,
      verbFormInfo,
      description,
    })

    const faqSchema = createFaqSchemaForPastTenseOf(faqs, word)

    return {
      ...baseInfoSchema,
      breadcrumb: breadcrumbSchema,
      mainEntity: mainEntitySchema,
      hasPart: [faqSchema],
    }
  } catch (error) {
    return undefined
  }
}
